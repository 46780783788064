import React from 'react'
import PropTypes from 'prop-types'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { IconButton } from '@material-ui/core'

const Employee = ({ name, role, image, empty, linkedin }) => {
    return (
        <div className={`employee ${empty && 'empty'}`}>
            {!empty && (
                <>
                    {image}
                    <span className="employeeName">
                        {name}
                        {linkedin && (
                            <IconButton href={linkedin} target="_blank" className="linkedInIcon">
                                <LinkedInIcon />
                            </IconButton>
                        )}
                    </span>
                    <span className="employeeRole">{role}</span>
                </>
            )}
        </div>
    )
}

Employee.propTypes = {
    name: PropTypes.string.isRequired,
    role: PropTypes.string,
    image: PropTypes.node,
    empty: PropTypes.bool,
    linkedin: PropTypes.string,
}

Employee.defaultProps = {
    role: '',
    image: null,
    empty: false,
    linkedin: '',
}

export default Employee
