import React from 'react'
import { useTranslation } from 'react-i18next'
import Employee from './Employee'

import Antoine from './images/Antoine'
import Molly from './images/Molly'
import Alexandre from './images/Alexandre'
import Laurence from './images/Laurence'
import ProBono from '../../assets/svg/plus30.svg'

const EmployeeGrid = () => {
    const { t } = useTranslation('about')

    const employees = [
        {
            name: 'Antoine Veilleux',
            role: t('cofounder'),
            image: <Antoine />,
            linkedin: 'https://www.linkedin.com/in/antoine-perspectiveaffaires-artbeats/',
        },
        // { name: 'empty', empty: true },
        { name: 'Molly Dupont Morin', role: t('digitalMagician'), image: <Molly /> },
        { name: 'Alexandre Bachaalani', role: t('momentCatcher'), image: <Alexandre /> },
        { name: 'Laurence Tremblay', role: t('graphicCreator'), image: <Laurence /> },
        { name: t('contributors'), image: <ProBono /> },
    ]

    return (
        <div className="employeeGrid">
            {employees.map(employee => (
                <Employee
                    key={employee.name}
                    name={employee.name}
                    role={employee.role}
                    image={employee.image}
                    empty={employee.empty}
                    linkedin={employee.linkedin}
                />
            ))}
        </div>
    )
}

export default EmployeeGrid
