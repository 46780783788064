import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Contact } from '../../utils/navLinks'
import Link from '../link'
import OutlinedButton from '../outlinedButton/OutlinedButton'

const ContactPreview = () => {
    const { t } = useTranslation('contact')
    return (
        <div className="card contactPreviewCard">
            <h3 className="cardTitle">
                {t('previewTitlePart1')}
                <br />
                {t('previewTitlePart2')}
            </h3>
            <OutlinedButton
                label={t('misc:letsTalk')}
                to={Contact.path}
                // eslint-disable-next-line no-unused-vars
                component={forwardRef((props, ref) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Link {...props} />
                ))}
            />
        </div>
    )
}

export default ContactPreview
