import React from 'react'
import { useTranslation } from 'react-i18next'

import Layout from '../components/layout'
import LogoGridCard from '../components/cards/LogoGridCard'
import SEO from '../components/seo'
import ContactPreview from '../components/cards/ContactPreview'
import EmployeeGrid from '../components/employeeGrid/EmployeeGrid'

import Mission from '../assets/svg/mission.svg'
import Vision from '../assets/svg/vision.svg'

function NotFoundPage() {
    const { t } = useTranslation('about')

    return (
        <Layout>
            <SEO title="about:title" description="about:description" />
            <h1 className="pageTitle">{t('header')}</h1>
            <div className="cardGrid">
                <div className="card missionCard">
                    <h3 className="cardTitle">{t('missionCardTitle')}</h3>
                    <p className="cardParagraph">{t('missionCardDescription')}</p>
                    <Mission />
                </div>
                <div className="card visionCard">
                    <h3 className="cardTitle">{t('visionCardTitle')}</h3>
                    <p className="cardParagraph">{t('visionCardDescription')}</p>
                    <Vision />
                </div>
            </div>
            <div className="card employeeGridCard">
                <h3 className="cardTitle">{t('employeeGridTitle')}</h3>
                <EmployeeGrid />
            </div>
            <LogoGridCard />
            <ContactPreview />
        </Layout>
    )
}

export default NotFoundPage
